import { Injectable } from '@angular/core';
import { parse } from 'path';

@Injectable({
  providedIn: 'root'
})
export class DistributionsFeesService {

  constructor() { }
  public getManagerFeesInTransfert(transfert,managerId,agency_type,account_type,agency_manager_level){

    let frais = 0
    let reste_frais =0

    if(transfert.type == 'charge' ){
      return frais;
    }
    if(agency_type == 'suspended'){
      return reste_frais
    }
    if(account_type == 'cag' && transfert.bank_operation==1 ){
      return reste_frais
    }
    if(transfert.type=='retour' && account_type=='cag' &&agency_type!='tempon' ){
      if(transfert.withdraw==1){
        return frais;
      }
      return parseFloat(transfert.fee)
    }
    if( transfert.type == 'retour'  &&( agency_type!='tempon')){
      if(transfert.withdraw==1){
        return frais;
      }
      if(account_type=='cag'){return parseFloat(transfert.fee)}
      if(transfert.level_depart <2  ){
        return parseFloat(transfert.fee);
      }else{
        if(account_type!='cag'){
        let distributions_frais =  JSON.parse(transfert.distributions);
        reste_frais = parseFloat(transfert.fee) - parseFloat(distributions_frais[0]);
        return reste_frais
        }else{
          return parseFloat(transfert.fee)
        }
      }
    }
    /*if((agency_type && agency_type=='tempon')||(transfert.level_depart>1 && account_type=='cm')){

      ////console.log('Hello World new fees')
      let distributions_fees = JSON.parse(transfert.distributions)
      if(distributions_fees.length >0){


      let fees = parseFloat(transfert.fee) - parseFloat(distributions_fees[0])
      return fees
      }

    }*/
     if((agency_type && agency_type=='tempon')){

      ////console.log('Hello World new fees')
      let distributions_fees = JSON.parse(transfert.distributions)
      if(distributions_fees.length >0){


      let fees = parseFloat(transfert.fee) - parseFloat(distributions_fees[0])
      return fees
      }

    }
    if(transfert.level_depart>1 && account_type=='cm' && agency_manager_level == 2 && transfert.distributions){

      let distributions_fees = JSON.parse(transfert.distributions)
      if(distributions_fees.length >0){


      let fees = parseFloat(transfert.fee) - parseFloat(distributions_fees[0])
      return fees
      }
     return reste_frais
    }
    if(transfert.type == 'pick_retour' && transfert.withdraw == 1){return reste_frais}
    if(transfert.type!=='pick_retour' && account_type =='cag' && (transfert.level_depart>1 && agency_manager_level <2  )){
      return frais;
    }
    if(transfert.type!=='pick_retour' && account_type =='cag' && (transfert.level_depart>1 && agency_manager_level >1  )){
      return frais;
    }


     if(transfert.distributions && (transfert.type_transfert == 'normal' || transfert.type_transfert == 'paiement_facture' ) ){
       let parcours =JSON.parse(transfert.parcours)
     let distribution_frais = JSON.parse(transfert.distributions)
     let index_frais = parcours.indexOf(managerId)
     ////console.log("Index of  ",parcours.indexOf(managerId));
     ////console.log("distr ",distribution_frais)
     //console.log("la distributions ",distribution_frais[index_frais])

     if(transfert.type !=='pick_retour'){
      reste_frais = parseFloat(transfert.fee)
      if((parcours.length>1) && (transfert.level_depart>1) && (agency_manager_level>1)){
        //console.log("parcours 2222|")
        reste_frais = parseFloat(transfert.fee)
      }else{
        //console.log("parcours ----|",parcours, "  #### distributions ",distribution_frais)
        //console.log("--------------- help dist --------",parcours.indexOf(managerId))
        for(let i=0;i<=index_frais;i++){

          reste_frais = reste_frais - parseFloat(distribution_frais[i])
        }


      }

    }
    else{
      let dist_inverse = distribution_frais.reverse();
      let parcours_reverse= parcours.reverse()
      //console.log("Reverse parcours et distribution ",dist_inverse ,"parc inv ",parcours_reverse )
      let index_frais = parcours.indexOf(managerId)
      for(let i=0;i<index_frais;i++){
        reste_frais = reste_frais + parseFloat(distribution_frais[i])
       }

      //return distribution_frais[index_frais]

    }
     return reste_frais;
     //return (parseFloat(transfert.fee)-parseFloat(distribution_frais[index_frais]))
     }else{
       return (frais) ;
     }
   }

   public   getManagerFees(transfert,accounts,agency_type,agency_level_manager){

    let reste_frais=0
    if(transfert.type == 'charge'){
      return reste_frais;
    }
    if(agency_type == 'suspended'){
      return reste_frais
    }
    if(accounts.type == 'cag' && transfert.bank_operation==1 ){
      return reste_frais
    }
    /*if(transfert.type == 'retour' && agency_type!='tempon'){
      return parseFloat(transfert.fee);
    }*/
    /*if((agency_type && agency_type=='tempon')||(transfert.level_depart>1 && accounts.type == 'cm')){

      ////console.log('Hello World new fees')
      let distributions_fees = JSON.parse(transfert.distributions)
      if(distributions_fees.length >0){


      let fees = parseFloat(transfert.fee) - parseFloat(distributions_fees[0])
      return fees
      }

    }*/

    if((agency_type && agency_type=='tempon')){

      ////console.log('Hello World new fees')
      let distributions_fees = JSON.parse(transfert.distributions)
      if(distributions_fees.length >0){


      let fees = parseFloat(transfert.fee) - parseFloat(distributions_fees[0])
      return fees
      }

    }
    if(transfert.type=='retour' && accounts.type=='cag' &&agency_type!='tempon' ){
      if(transfert.withdraw==1){
        let restefrais=0
        return restefrais;
      }
      return parseFloat(transfert.fee)
    }
    if(transfert.type == 'pick_retour' && transfert.withdraw == 1){return reste_frais}


    if(transfert.distributions && (transfert.type_transfert == 'normal' || transfert.type_transfert == 'paiement_facture' )){
      let parcours =JSON.parse(transfert.parcours)
    let distribution_frais = JSON.parse(transfert.distributions)
    let index_frais = parcours.indexOf(accounts.id_user)
    //console.log("Index of managerfes  ",parcours.indexOf(accounts.id_user));
    //console.log("distr ",distribution_frais, "account :", accounts)
    //console.log("la distributions ",distribution_frais[index_frais])
    reste_frais = parseFloat(transfert.fee)
    if(transfert.type=='retour' && accounts.type!='cag' && transfert.level_depart>1){
      if(transfert.withdraw==1){return 0;}
      reste_frais =reste_frais-parseFloat(distribution_frais[0])
      return reste_frais;
    }
    if((accounts.type == 'csm'  )&& (transfert.level_depart>1)){
      //reste_frais=parseFloat(distribution_frais[index_frais])
      for(let i=0;i<=index_frais;i++){

        reste_frais = reste_frais - parseFloat(distribution_frais[i])
      }
      return  reste_frais;
    }else{

      //console.log("managerFeess -----",index_frais)
      for(let i=0;i<=index_frais;i++){
        reste_frais = reste_frais - parseFloat(distribution_frais[i])
       }
    }


    return  reste_frais;

    }else{
      return reste_frais;
    }
  }
  public  getTotauxOperations(liste,accounts,agency_type,agency_manager_level){
   let account_amount_in =  this.accountGetTotalAmountIn(liste,accounts,agency_type,agency_manager_level)
   let account_amount_out = this.accountGetTotalAmountOut(liste,accounts,agency_type,agency_manager_level)
   return {"account_amount_in":account_amount_in,"account_amount_out":account_amount_out}
  }

  public accountGetTotalAmountOut(liste,accounts,agency_type,agency_manager_level){
    let total_amount_out =0
    let total_fee_out =0
    liste.forEach(transaction => {
      if(accounts.id == transaction.id_account_credit){
        total_amount_out = total_amount_out+parseFloat(transaction.amount)
        if(transaction.type!=='pick_retour' && transaction.type !=='retour'){
          //if(transaction.withdraw !=1){
            total_fee_out = (total_fee_out)+ (transaction.montant_debit -transaction.amount)
            //total_fee_out = (total_fee_out) + this.getManagerFeesInTransfert(transaction,accounts.id_manager,agency_type,accounts.type,agency_manager_level)
          //}

        }else{
          if(transaction.withdraw !=1){
         // total_fee_out = (total_fee_out) + this.getManagerFees(transaction,accounts,agency_type,agency_manager_level)
         total_fee_out = (total_fee_out)+ (transaction.montant_debit -transaction.amount)
          
        }
        }
      }
      });
      if(agency_type=='suspended'){total_fee_out=0;}
      return {"total_fee_out":total_fee_out,"total_amount_out":total_amount_out}

  }

  public accountGetTotalAmountIn(liste,accounts,agency_type,agency_manager_level){
    let total_amount_in =0
    let total_fee_in =0
    liste.forEach(transaction => {
     
      if(accounts.id == transaction.id_account_debit){
        total_amount_in = total_amount_in+parseFloat(transaction.amount)
      if(transaction.type!=='pick_retour' && transaction.bank_operation!=1){
        if(1){
          if(transaction.id_agence_from==945 || transaction.id_agence_to==945)
         total_fee_in = total_fee_in + parseFloat(transaction.montant_debit) - parseFloat(transaction.amount)
            else    
          total_fee_in = (total_fee_in) + this.getManagerFees(transaction,accounts,agency_type,agency_manager_level)

         //console.log("TRACKK***",parseFloat(transaction.montant_debit), parseFloat(transaction.amount),total_fee_in)
        }else{
          if((agency_type && agency_type=='tempon')||(transaction.level_depart>1 && accounts.type == 'cm')){

            if(transaction.distributions){
              let distributions_fees = JSON.parse(transaction.distributions)
              if(distributions_fees.length >0){
             // let fees = parseFloat(transaction.fee) - parseFloat(distributions_fees[0])
              total_fee_in = total_fee_in + parseFloat(transaction.montant_debit) - parseFloat(transaction.amount)

              }

            }

          }else{
            if((transaction.level_depart>1 && agency_manager_level <2  )){

            }else{
               //if(transaction.withdraw !=1){
                if(transaction.id_agence_from==945 || transaction.id_agence_to==945)
                  total_fee_in = total_fee_in + parseFloat(transaction.montant_debit) - parseFloat(transaction.amount)

                else
                total_fee_in = (total_fee_in) + parseFloat(transaction.fee)

            //}
            }

          }

        }
      }
      else{
        if(transaction.id_agence_from==945 || transaction.id_agence_to==945)
          total_fee_in = (total_fee_in) + (transaction.montant_debit - transaction.amount)
        else
        total_fee_in = (total_fee_in) + this.getManagerFeesInTransfert(transaction,accounts.id_manager,agency_type,accounts.type,agency_manager_level)
        
      
       // console.log("track002",total_fee_in, transaction.id_agence_from, transaction.id_agence_to)
      }

    }
      });
      if(agency_type=='suspended'){total_fee_in=0;}
      return {"total_fee_in":total_fee_in,"total_amount_in":total_amount_in}

  }
}
